import React, { FC } from 'react';
import './index.css';
import { ThemeProvider } from 'styled-components';
import { Theme } from '../util/styledComponents';
import { SiteContextProvider } from '../context';

type Props = {};

const Layout: FC<Props> = props => {
  return (
    <SiteContextProvider>
      <ThemeProvider theme={Theme}>
        <main>{props.children}</main>
      </ThemeProvider>
    </SiteContextProvider>
  );
};

export default Layout;

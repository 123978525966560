import { breakpoint } from '../util/constant';
import React, { FC, useState, useEffect } from 'react';
import scrollToElement from 'scroll-to-element';

export interface State {
  readonly deviceSize: breakpoint;
  readonly browserWidth: number;
  readonly scrollHeight: number;
  readonly scrollAtTop: boolean;
  setScrollTop(value: boolean): void;
}

export const InitialState: State = {
  deviceSize: breakpoint.desktop,
  browserWidth: 0,
  scrollHeight: 0,
  scrollAtTop: false,
  setScrollTop: () => {}
};

export const SiteContext = React.createContext(InitialState);

type Props = {};

export const SiteContextProvider: FC<Props> = ({ children }) => {
  const [deviceSize, setDeviceSize] = useState(breakpoint.desktop);
  const [browserWidth, setBrowserWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollAtTop, setScrollAtTop] = useState(false);

  useEffect(() => {
    setBrowserWidth(browserWidth);
  }, [browserWidth]);

  useEffect(() => {
    const windowScrollEvent = () => {
      setScrollHeight(window.scrollY);
    };

    if (scrollHeight !== window.scrollY) {
      setScrollHeight(window.scrollY);
    }

    window.addEventListener('scroll', windowScrollEvent);

    const setSize = () => {
      setBrowserWidth(
        window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
      );
    };

    setSize();

    window.addEventListener('resize', setSize);

    if (window.location.pathname === '/' && window.location.hash !== '') {
      scrollToElement(`${window.location.hash}`, {
        offset: 0,
        ease: 'outQuad',
        duration: 1000
      });
    }
  }, []);

  useEffect(() => {
    let size = breakpoint.desktop;
    if (browserWidth <= 480) {
      size = breakpoint.mobile;
    } else if (browserWidth <= 768) {
      size = breakpoint.tablet;
    }
    if (size !== deviceSize) setDeviceSize(size);
  }, [browserWidth]);

  useEffect(() => {
    if (
      scrollAtTop === true &&
      deviceSize !== breakpoint.mobile &&
      window.location.pathname === '/'
    ) {
      window.scrollTo({
        top: window.innerHeight,
        behavior: 'smooth'
      });
    }

    if (scrollHeight === 0 && window.location.pathname === '/') {
      if (!scrollAtTop) setScrollAtTop(true);
    } else {
      if (scrollAtTop) setScrollAtTop(false);
    }
  }, [scrollHeight]);

  return (
    <SiteContext.Provider
      value={{
        deviceSize,
        browserWidth,
        scrollAtTop,
        scrollHeight,
        setScrollTop: setScrollAtTop
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

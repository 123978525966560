import { css } from "styled-components";
import { generateMedia } from "styled-media-query";

export const Theme = {
  colour: {
    primary: "#E5056A",
    primaryLight: "#ef258a",
    primaryDark: "#b01285",
    secondary: "#f6b12c",
    secondaryLight: "#f7c341",
    secondaryDark: "#f27f23",
    accent: "#00b259",
    accent2: "#009149",
    white: "white",
    grey1: "rgb(240, 240, 240)",
    grey2: "rgb(220, 220, 220)",
    grey3: "rgb(200, 200, 200)",
    grey4: "rgb(180, 180, 180)",
    grey5: "rgb(160, 160, 160)",
    dark: "rgb(80, 80, 80)",
    darkest: "rgb(30, 30, 30)"
  },
  fontSize: {
    body: "16px",
    smallHeading: "22px",
    largeHeading: "30px"
  },
  breakpoints: {
    mobile: "480px",
    tablet: "768px",
    browser: "1000px"
  }
};

export const deviceSize = generateMedia({
  mobile: "480px",
  tablet: "768px",
  desktop: "1000px"
});

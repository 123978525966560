export enum breakpoint {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop'
}

export enum Sort {
  default = 'Default',
  dateDESC = 'Date DESC',
  dateASC = 'Date ASC',
  AZ = 'A-Z',
  ZA = 'Z-A'
}

export enum ProjectComponent {
  Title = 'ContentfulComponentTitle',
  Video = 'ContentfulComponentVideo',
  Quote = 'ContentfulComponentQuote',
  Paragraph = 'ContentfulComponentParagraph',
  Image = 'ContentfulComponentImage',
  Images = 'ContentfulComponentImages',
  Iframe = 'ContentfulComponentIframe',
  Credits = 'ContentfulComponentCredits',
  Links = 'ContentfulComponentLinks',
  Gallery = 'ContentfulComponentGallery'
}

export enum Share {
  facebook = 'facebook',
  linkedin = 'linkedin',
  mail = 'mail',
  twitter = 'twitter'
}

export enum ProjectTag {
  animation = 'animation',
  audio = 'audio',
  commercial = 'commercial',
  corporate = 'corporate',
  digital = 'digital',
  film = 'film',
  music = 'music',
  podcast = 'podcast',
  social = 'social',
  video = 'video'
}

export const ProjectTagList = [
  ProjectTag.animation,
  ProjectTag.audio,
  ProjectTag.commercial,
  ProjectTag.corporate,
  ProjectTag.digital,
  ProjectTag.film,
  ProjectTag.music,
  ProjectTag.podcast,
  ProjectTag.social,
  ProjectTag.video
];

export const ProjectTagListPartial = [
  ProjectTag.animation,
  ProjectTag.audio,
  ProjectTag.digital,
  ProjectTag.music,
  ProjectTag.video
];
